import React, { useEffect, useState } from 'react';
import heroImage1 from '../../../public/heroImage.png';
import heroImage2 from '../../../public/heroImage2.png';
import heroImage3 from '../../../public/heroImage3.png';
import heroImage4 from '../../../public/heroImage4.png';
import heroImage5 from '../../../public/heroImage5.png';
import heroImage6 from '../../../public/heroImage6.png';
import heroImage7 from '../../../public/heroImage7.png';
import { Box, Text, Button, Flex, Image } from 'theme-ui';
import { useProspectContext } from '../../../context/ProspectContext';
import { clickEvent } from '../../../utils/gtm';

function getRandomHeroImage() {
  const heroImages = [
    heroImage1,
    heroImage2,
    heroImage3,
    heroImage4,
    heroImage5,
    heroImage6,
    heroImage7,
  ];

  return heroImages[Math.floor(Math.random() * heroImages.length)];
}

export default function SectionHeroContent() {
  const [image, setImage] = useState('');
  const { setOpen } = useProspectContext();

  useEffect(() => {
    setImage(() => getRandomHeroImage());
  }, [setImage]);

  return (
    <Box as="section" id="hero" sx={{ transition: '.3s linear' }}>
      <Flex
        sx={{
          padding: '3rem 12rem 0 12rem',
          justifyContent: 'center',
          '@media screen and (max-width: 1500px)': {
            padding: '3rem 3rem 0 3rem',
          },
          '@media screen and (max-width: 1280px)': {
            padding: '3rem 3rem 0 3rem',
          },
          '@media screen and (max-width: 1200px)': {
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '3rem',
          },
          '@media screen and (max-width: 630px)': {
            padding: '3rem 0.5rem 0 0.5rem',
          },
        }}>
        <Flex
          sx={{
            maxWidth: '670px',
            width: '100%',
            flexDirection: 'column',
            padding: '0 15px',
            alignSelf: 'center',
            '@media screen and (max-width: 1200px)': {
              alignItems: 'center',
            },
          }}>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text
              as="h1"
              color="black"
              sx={{
                '@media screen and (max-width: 1200px)': {
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '60px',
                },
                '@media screen and (max-width: 1024px)': {
                  fontSize: '50px',
                },
                '@media screen and (max-width: 768px)': {
                  fontSize: '42px',
                },
                '@media screen and (max-width: 560px)': {
                  fontSize: '37px',
                },
              }}>
              Você mais protegido
              <Text as="strong" sx={{ display: 'block' }}>
                e sem preocupações.
              </Text>
            </Text>
          </Flex>
          <Text
            as="p"
            pr="7rem"
            sx={{
              color: 'darkGray',
              fontSize: '22px',
              marginTop: '20px',
              '@media screen and (max-width: 1200px)': {
                textAlign: 'center',
                paddingRight: '0',
                fontSize: '20px',
                marginTop: '15px',
              },
              '@media screen and (max-width: 1024px)': {
                textAlign: 'center',
                paddingRight: '0',
                fontSize: '18px',
                marginTop: '10px',
              },
              '@media screen and (max-width: 768px)': {
                fontSize: '16px',
              },
              '@media screen and (max-width: 560px)': {
                fontSize: '14px',
              },
            }}>
            A Flix é uma Plataforma de distribuição que te entrega um seguro residencial descomplicado e acessível, para você aproveitar o melhor da sua vida sem dores de cabeça!
          </Text>
          {/*<Button*/}
          {/*  onClick={() => {*/}
          {/*    setOpen(true);*/}
          {/*    clickEvent('clique_btn_dobra_1');*/}
          {/*  }}*/}
          {/*  title="Quero Flix!"*/}
          {/*  variant="buttons.primary"*/}
          {/*  sx={{*/}
          {/*    width: '280px',*/}
          {/*    marginTop: '30px',*/}
          {/*    '@media screen and (max-width: 768px)': {*/}
          {/*      marginTop: '14px',*/}
          {/*      height: '47px',*/}
          {/*      maxWidth: '240px',*/}
          {/*    },*/}
          {/*  }}>*/}
          {/*  Quero Flix!*/}
          {/*</Button>*/}
        </Flex>
        <Box>
          <Image
            src={image}
            sx={{
              width: '100%',
              height: '100%',
              '@media (max-width:1200px)': {
                maxWidth: '400px',
                marginTop: '20px',
              },
              '@media (max-width:768px)': {
                maxWidth: '300px',
                marginTop: '15px',
              },
              '@media (max-width: 500px)': {
                maxWidth: '250px',
                marginTop: '10px',
              },
              '@media (max-width: 400px)': {
                maxWidth: '220px',
              },
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
}
