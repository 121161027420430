import React from 'react';
import { Box, Text, Button, Flex } from 'theme-ui';
import { useProspectContext } from '../../../context/ProspectContext';
import { clickEvent } from '../../../utils/gtm';

export default function SectionFlixAndDot() {
  const { setOpen } = useProspectContext();

  return (
    <Box
      as="section"
      px="12%"
      sx={{
        position: 'relative',
        '@media screen and (max-width: 1024px)': { padding: '0' },
      }}>
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          padding: '5rem 0',
          margin: '0 auto',
          '@media screen and (max-width: 1400px)': { padding: '5rem 0' },
          '@media screen and (max-width: 1200px)': { padding: '4rem 0' },
          '@media screen and (max-width: 1024px)': { padding: '3rem 0' },
          '@media screen and (max-width: 768px)': { padding: '2rem 0' },
          '@media screen and (max-width: 630px)': { padding: '25pxrem 0' },
        }}>
        <Text
          as="h1"
          sx={{
            '@media screen and (max-width: 1024px)': {
              textAlign: 'center',
              fontSize: '45px',
              color: 'black2business',
            },
            '@media screen and (max-width: 768px)': {
              fontSize: '38px',
            },
            '@media screen and (max-width: 630px)': {
              fontSize: '30px',
            },
          }}>
          Viu só?
        </Text>
        <Text
          as="h1"
          sx={{
            '@media screen and (max-width: 1024px)': {
              textAlign: 'center',
              fontSize: '45px',
              color: 'black2business',
            },
            '@media screen and (max-width: 768px)': {
              fontSize: '38px',
            },
            '@media screen and (max-width: 630px)': {
              fontSize: '30px',
            },
          }}>
          É Flix e ponto.
        </Text>
        {/*<Button*/}
        {/*  onClick={() => {*/}
        {/*    setOpen(true);*/}
        {/*    clickEvent('clique_btn_dobra_6');*/}
        {/*  }}*/}
        {/*  title="Vem pra Flix"*/}
        {/*  variant="buttons.primary"*/}
        {/*  mt="4"*/}
        {/*  sx={{*/}
        {/*    maxWidth: '290px',*/}
        {/*    width: '100%',*/}
        {/*    '@media screen and (max-width: 450px)': { width: '90%' },*/}
        {/*    '@media screen and (max-width: 1024px)': {*/}
        {/*      marginTop: '30px',*/}
        {/*      height: '47px',*/}
        {/*    },*/}
        {/*    '@media screen and (max-width:768px)': {*/}
        {/*      marginTop: '20px',*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  data-cy="button-modal-prospect">*/}
        {/*  Quero Flix!*/}
        {/*</Button>*/}
      </Flex>
    </Box>
  );
}
